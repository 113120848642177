.multiStepsFormPage {
  width: 83.333333%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circlesDiv {
  width: 66.666667%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem/* 24px */;
  line-height: 2rem/* 32px */;
  margin: 1rem 0;
}

.circleDiv {
  display: flex;
  align-items: center;
}

.circleDiv:last-child {
  width: fit-content;
}

.circle {
  width: fit-content;
  padding: 1rem 1.5rem;
  border-width: 4px;
  border-radius: 9999px;
}

.circleActive {
  width: fit-content;
  padding: 1rem 1.5rem;
  border-width: 4px;
  border-radius: 9999px;
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.circleSpan {
  height: 0.25rem/* 2px */;
  margin-left: 1rem/* 16px */;
  margin-right: 1rem/* 16px */;--tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.circleSpanActive {
  height: 0.25rem/* 2px */;
  margin-left: 1rem/* 16px */;
  margin-right: 1rem/* 16px */;--tw-bg-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));

}

.lastSpanHidden {
  display: none;
}

.multiStepsForm {
  width: 50%;
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.multiStepsResetButton {
  width: fit-content;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
  border-radius: 1.5rem/* 24px */;
  padding: 0.5rem 2rem;
}

.multiStepsButtonDiv {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  margin-bottom: 5rem/* 80px */;
  margin-top: 1rem/* 16px */;
}

.multiStepsNextButtonDiv {
  display: flex;
  align-items: center;
  width: fit-content;
}

.multiStepsButtonPrevious {
  width: fit-content;
  padding: 0.5rem 1.25rem;
}

.multiStepsButtonNext {
  width: fit-content;
  padding: 0.5rem 2.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: 1.5rem/* 24px */;
}
