@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  width: 100%;
}

body {
  margin: 0;
}

.App {
  text-align: center;
  padding-top: 64px;
}

input, select {
  background-color: #f2f2f2;
  height: 36px;
  padding: 8px;
  border-radius: 0.25rem;
}

label {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.25rem;
}

.spanMoneyInput {
  background-color: #f2f2f2;
  border-radius: 0.25rem;
  padding-left: 8px;
}

th {
  width: auto;
  font-size: smaller;
  font-weight: 400;
}

.modal_inactive {
  display: none;
}

.modal_active {
  background-color: #000000ab;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
}

.generatePDFTitle {
  color: white;
  margin-bottom: 36px;
  margin-top: -64px;
  font-size: 36px;
}

.stepsinput {
  background-color: white;
  color: black;
  border: 2px solid #f2f2f2;
  border-radius: 1.5rem;
  padding-left: 24px;
}

#spanstepsinput {
  border: none;
  background-color: white;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  padding-left: 4px;
}

.stepsselect {
  background-color: white;
  color: black;
  border: 2px solid #f2f2f2;
  padding: 0 24px;
  border-radius: 1.5rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  background-image: linear-gradient(white, white),
    linear-gradient(-135deg, transparent 50%, white 50%),
    linear-gradient(-225deg, transparent 50%, white 50%),
    linear-gradient(white 42%, black 42%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
  background-position: right 20px center, right bottom, right bottom, right bottom; 
}

.currencyinput {
  background-color: white;
  height: 36px;
  padding: 8px;
  border-radius: 1.5rem;
  border: 2px solid #f2f2f2;
}

.loader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.834);
  z-index: 100;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: rgb(185 28 28)transparent rgb(185 28 28) transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
